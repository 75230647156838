import { Constants, errorLogger } from '../../../../lib/ads/utils'
import { dispatchCustomEvent } from '../../../../lib/utils/events'

const STATUS = {
  Created: 0,
  Destroyed: 1,
  Initialized: 2,
  Rendered: 3,
  Pending: 4,
  Empty: 5,
  FallbackRendered: 6
}
const CLASS_PLACEHOLDER = 'm-gam__placeholder'
const ClASS_AD_LBL = 'm-gam__ad-lbl'
const CLASS_SMALL_AD_MPU_LOADED = 'mpu--small'
const CLASS_STACK_AD_MPU_LOADED = 'mpu--stack'
const CLASS_SMALL_AD_BTF_LOADED = 'btf--small'
const CLASS_STACK_AD_BTF_LOADED = 'btf--stack'
const CLASS_PREMIUM_AD = 'benji-premium-ad'

export default (() => {
  class GamPosition {
    constructor(config, elem) {
      if (!config) {
        return
      }
      this.id = config.id
      this.config = config
      this.isFluid = this._isFluid()
      this.maxConfiguredWidth = this._getMaxWidth()
      this.elem = elem || document.getElementById(this.id)
      this.adLbl = this.elem?.parentElement?.querySelector(`.${ClASS_AD_LBL}`)
      this.status = STATUS.Initialized
      this.onEmptyCallback = () => {}
    }

    static fromElement(elem, instance) {
      const logger = errorLogger()
      if (!elem) {
        return logger(`[GAM ${Constants.ERROR_LEVEL.WARN}] Element not found`)
      }
      let elemConfig
      try {
        elemConfig = JSON.parse(elem.dataset.config)
        if (instance) {
          const instanceId = `${elemConfig.id}_${instance}`
          elem.id = instanceId
          elemConfig.id = instanceId
          if (elemConfig.kvs.loc) {
            elemConfig.kvs.loc = `${elemConfig.kvs.loc}_${instance}`
          }
        }
      } catch (e) {
        return logger(`[GAM ${Constants.ERROR_LEVEL.ERROR}] Error parsing config for', ${elem?.id}`)
      }
      return new GamPosition(elemConfig, elem)
    }

    get region() {
      return this.config.region || 'index'
    }

    get isRendered() {
      return this.status === STATUS.Rendered
    }

    get isPending() {
      return this.status === STATUS.Pending
    }

    get loc() {
      return this.config?.kvs?.loc
    }

    get stackGroup() {
      return this.config?.stackGroup || false
    }

    get isPremiumAd() {
      return this.elem.classList.contains(CLASS_PREMIUM_AD)
    }

    handleWindowResize() {
      if (this.status === STATUS.FallbackRendered || !this._validateViewability()) return
      const hasRoomToRender = this.hasRoomToRender()
      if (!hasRoomToRender) {
        this.destroy()
      } else {
        this.create()
      }
    }

    handleWindowScroll(validateOnly = false) {
      if (this.status !== STATUS.Initialized) {
        return false
      }
      if (!this.hasRoomToRender()) {
        this.destroyPlaceholder()
        this._collapseMargin()
      }
      if (this._validateViewability() && this.hasRoomToRender()) {
        !validateOnly && this.create()
        return true
      }
      return false
    }

    hasRoomToRender() {
      // If the ad has not yet rendered, but could be an e2e ad, we need render it to find out.
      if (this.status === STATUS.Initialized && this._supportsE2eAdSize()) {
        return true
      }
      // Fluid ads are responsive and thus there is always room for them to render
      if (this.isFluid || this.isPremiumAd) {
        return true
      }
      // If the container width is smaller than the maximum possible ad which could render
      return this.elem?.parentElement?.offsetWidth >= this.maxConfiguredWidth
    }

    destroyPlaceholder() {
      this.elem.style.minHeight = null
      this.elem.style.minWidth = null
      const placeholder = this.elem?.querySelector(`.${CLASS_PLACEHOLDER}`)
      if (!placeholder) {
        return
      }
      placeholder.parentElement.removeChild(placeholder)
    }

    destroy() {
      if (this.status === STATUS.Destroyed) {
        return
      }
      window.benji?.destroy?.([this.id])
      this.destroyPlaceholder()
      this._collapseMargin()
      this.status = STATUS.Destroyed
    }

    create() {
      if (this.status === STATUS.Created) {
        return
      }
      window.benji?.render?.({ [this.id]: this.config })
      this.setCreatedStatus()
    }

    setOnEmptyCallback(fn = () => {}) {
      this.onEmptyCallback = fn
    }

    onRender(event = {}) {
      if (event.isEmpty) {
        this.status = STATUS.Empty
        // This is only here for debugging
        // const extraDebugInfo = {
        //   divId: this.id,
        //   adPath: this.config?.path
        // }
        // const logger = errorLogger()
        // logger(`[GAM ${Constants.ERROR_LEVEL.WARN}] Empty Ad`, extraDebugInfo)
        // End of debugging
        this.onEmptyCallback?.(event)
        if (this.loc === 'top_center') {
          this._emitHeaderAdLoad(event)
        }
        this.elem?.classList.remove('m-gam--loaded')
        this._collapseMargin()
        return
      }
      this.elem?.classList.add('m-gam--loaded')
      this._uncollapseMargin()
      const [width = this.maxConfiguredWidth, height] = event.size || []
      if (this.loc === 'top_center') {
        this._emitHeaderAdLoad(event)
      }
      this._updateAdLblWidth(width)

      // If a small top_right add loads, we add a class to the document in order to style the page accordingly
      if (['top_right', 'mid_right'].includes(this.loc)) {
        let className
        switch (this.loc) {
          case 'mid_right':
            className = this.stackGroup ? CLASS_STACK_AD_BTF_LOADED : CLASS_SMALL_AD_BTF_LOADED
            break
          case 'top_right':
          default:
            className = this.stackGroup ? CLASS_STACK_AD_MPU_LOADED : CLASS_SMALL_AD_MPU_LOADED
        }
        if (height === 250) {
          document.documentElement.classList.add(className)
        } else {
          document.documentElement.classList.remove(className)
        }
      }
      this.status = STATUS.Rendered
    }

    onRequested(event = {}) {
      this.status = STATUS.Pending
    }

    _emitHeaderAdLoad(event) {
      dispatchCustomEvent('header:adLoad', event)
    }

    refresh() {
      window.benji?.refresh?.([this.id])
    }

    setCreatedStatus() {
      // There is a use case where on resize a fluid unit get re-created, in this case we don't want to set the status to created, because it is already rendered
      if (!this.isRendered) {
        this.status = STATUS.Created
      }
    }

    setFallbackRenderedStatus() {
      this.status = STATUS.FallbackRendered
    }

    _isFluid() {
      const { size = [] } = this.config
      return size.some(s => s === 'fluid')
    }

    _getMaxWidth() {
      if (this.isFluid) {
        return 0
      }
      // This gets the maximum width configured in the this.config.size array
      const { size = [] } = this.config
      const [maxAdWidth] = size.reduce(
        (maxWidth, currentWidth) => {
          const [mWidth] = maxWidth
          const [width] = currentWidth
          if (!mWidth || width > mWidth) {
            maxWidth = currentWidth
          }
          return maxWidth
        },
        [0, 0]
      )
      return maxAdWidth || 0
    }

    _supportsE2eAdSize() {
      const { customSizeConfig = [] } = this.config
      return Object.values(customSizeConfig).some(v => v === true)
    }

    _updateAdLblWidth(newWidth = this.maxConfiguredWidth) {
      if (this.adLbl) {
        this.adLbl.style.width = `${newWidth}px`
      }
    }

    _validateViewability() {
      // Viewability checks only matter before the ad loads for the first time and for lazy regions
      if (this.status !== STATUS.Initialized || this.config.region !== 'lazy') {
        return true
      }
      // Lazy loaded ads should only initialize if they are within window.innerHeight*2
      const rect = this.elem.getBoundingClientRect()
      return rect.top < window.innerHeight * 2
    }

    _collapseMargin() {
      // Don't collapse the margin if the ad is fluid or if it is part of a stack
      if (this.isFluid || this.stackGroup) return
      this.elem.parentElement?.setAttribute(
        'style',
        'margin-bottom:0 !important; margin-top:0 !important;'
      )
    }

    _uncollapseMargin() {
      if (this.isFluid || this.stackGroup) return
      this.elem.parentElement?.setAttribute('style', '')
    }
  }

  return GamPosition
})()
